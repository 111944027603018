import { ACCESS_TOKEN_KEY, AUTH_SERVER, EXPIRY, TOKEN_URL, LOGOUT_URL } from "@/modules/login/constants";
import { identityApi, restApi } from "@/http/http.config";
import jwt_decode from "jwt-decode";
import * as Roles from "@/security/rolesHelper";
import * as Sentry from "@sentry/vue";
import { appInsights } from "@/main";
import { 
  encodeWithParam,
  decode 
} from "@/filters";

class AuthenticationService {
  /**
   *
   * {Axios} @param api
   */
  constructor({ api }) {
    this.api = api;
  }

  /**
   * @param { Object } credentials
   * @param {String} credentials.username
   * @param {String} credentials.password
   * @returns {Promise<void>}
   */

   login = async (credentials) => {
     let data= await restApi.post(`employee/login`,encodeWithParam(credentials));
        if(data.data.success){
          let userObj= decode(data.data.result)
          const decodedToken = jwt_decode(userObj.token)
          // console.log(decodedToken.exp)
          const userID = userObj.user.userID;
          appInsights.setAuthenticatedUserContext(userID, "employee", true);
          this.storeAuth(userObj.token)
          this.storeExpiry(decodedToken.exp);
          
        }
        return data

      // .catch((error) => {
      //   return Promise.reject(error);
      // });
  };
  // login = (credentials) => {
  //   return this.api
  //     .post(TOKEN_URL, credentials, {
  //       baseURL: AUTH_SERVER,
  //       headers: { "auth-type": "password" },
  //       withCredentials: true,
  //     })
  //     .then(({ data }) => {
  //       console.log(data)
  //       const decodedToken = jwt_decode(data.access_token)
  //       const userID = decodedToken["https://hasura.io/jwt/claims"]["x-hasura-user-id"];
  //       appInsights.setAuthenticatedUserContext(userID, "employee", true);

  //       this.storeAuth(data.access_token)
  //       this.storeExpiry(data.expiry);
  //     })
  //     .catch((error) => {
  //       return Promise.reject(error);
  //     });
  // };

  logout = () => {
    return this.api.post(
      LOGOUT_URL,
      {},
      {
        baseURL: AUTH_SERVER,
        withCredentials: true,
        headers: { authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}` },
      }
    );
  };

  refreshToken = () => {
    return this.api
      .post(TOKEN_URL, {}, { baseURL: AUTH_SERVER, withCredentials: true, headers: { "auth-type": "refresh" } })
      .then(({ data }) => {
        this.storeExpiry(data.expiry);
        return data;
      })
      .catch((err) => {
        Sentry.captureException(err);
        return Promise.resolve({ data: null });
      });
  };

  resetPassword = (userID, token, password) => {
    return this.api.post(
      "/auth/resetpassword",
      { userID, token, password },
      {
        baseURL: AUTH_SERVER,
      }
    );
  };
  requestPasswordReset = (username) => {
    return this.api.post(
      "/auth/sendreset",
      { email: username },
      {
        baseURL: AUTH_SERVER,
      }
    );
  };

  isTokenValid = () => {
    const expiry = localStorage.getItem(EXPIRY);
    return !(Date.now() > expiry * 1000);
  };
  /**
   *
   * @param {object} data
   * @param {string} data.access_token
   * @param {number} data.expiry
   */
  storeAuth(data) {
    localStorage.setItem(ACCESS_TOKEN_KEY, data);
  }
  storeExpiry(data) {
    localStorage.setItem(EXPIRY, data);
  }

  loggedInUser() {
    return jwt_decode(localStorage.getItem(ACCESS_TOKEN_KEY));
  }

  homePageForLoggedInUser() {
    const user = this.loggedInUser();
    const employee = { role: user.role };
    return Roles.isAdminRole(employee) ? "onboarding" : "login";
  }
}

export default new AuthenticationService({ api: identityApi });
